import './App.css';
import Home from './components/Home';
import Main from './components/Main';

function App() {
  return (
    <div className="App">
   <Main/>
    </div>
  );
}

export default App;
