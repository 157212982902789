import React from 'react'
import "./Navbar.css"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const Navbar = ({user}) => {
  const navigate = useNavigate()
  const navi = () => {
    navigate("/",{replace:true})
  }
  return (
    <div id='navbar'>
      <div onClick={navi} id="logo"> <h3>Fable.<span style={{color:"#5c4ce3"}}>club</span></h3></div>
     
      <div id="log">
       <Link to={"/signin"} className='signup'>{user==="" ? "Sign in" : <>{user}</>}</Link>
      </div>
    </div>
  )
}

export default Navbar

