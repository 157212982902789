import React, { useState } from "react";
import "./signin.css";
import { Link, useNavigate } from "react-router-dom";
const Signin = ({ setusername }) => {
  const [login_data, setlogindata] = useState({
    email: "",
    password: "",
  });
  const [auth, setauth] = useState(false);
  const navigate = useNavigate();
  let login = async () => {
    if (
        login_data.email === "" ||
        login_data.password === ""
      ) {
        alert("all Fields are required");
        return;
      }
    try {
      let login_data_json = JSON.stringify(login_data);

      let res = await fetch("https://project-backend-kqz8.onrender.com/signin", {
        method: "POST",
        body: login_data_json,

        headers: {
          "Content-Type": "application/json",
        },
      });

      let data = await res.json();
      console.log(data);
      setusername(data.user.username);
      //   getUser(login_data.email, data.token);
      setauth(!auth);
    } catch (err) {
      alert("incorrect Email or password");
      console.log(err);
    }
  };

  //   let getUser = async (username, token) => {
  //     try {
  //       let res = await fetch(
  //         `https://masai-api-mocker.herokuapp.com/user/${username}`,
  //         {
  //           //method is by default;
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       let data = await res.json();
  //     //   console.log(data);

  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  if (auth) {
    navigate("/", { replace: true });
  }
  return (
    <div id="container">
      <div id="login-form">
        <h1>Login</h1>
        <form>
          <input
            required={true}
            onChange={(e) =>
              setlogindata({ ...login_data, email: e.target.value })
            }
            type="text"
            id="login-email"
            placeholder="Email"
          />
          <input
            required={true}
            onChange={(e) =>
              setlogindata({ ...login_data, password: e.target.value })
            }
            type="text"
            id="login-password"
            placeholder="Password"
          />
        </form>

        <button onClick={login}>Login</button>
        <p>
          Not Have Account?
          <span>
            <Link className="link" style={{ marginLeft: "0px" }} to={"/signup"}>
              Click Here to Signup
            </Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Signin;
