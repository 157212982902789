import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Signin from './Signin/Signin'
import Signup from './Signup/Signup'
import Navbar from './Navbar/Navbar'

const Main = () => {
    const [user,setusername] = useState("")
  return (
    <div>
        <Navbar user={user}/>
      <Routes>
        <Route path={"/"} element={<Home/>}></Route>
        <Route path={"/signin"} element={<Signin setusername={setusername}/>}></Route>
        <Route path={"/signup"} element={<Signup/>}></Route> 
      </Routes>

    </div>
  )
}

export default Main
