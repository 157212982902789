import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const [for_register_data, setregisterdata] = useState({
    username: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  let register = async () => {
    if (
      for_register_data.username === "" ||
      for_register_data.email === "" ||
      for_register_data.password === ""
    ) {
      alert("all Fields are required");
      return;
    }
    try {
      let register_data = JSON.stringify(for_register_data);

      let res = await fetch("https://project-backend-kqz8.onrender.com/signup", {
        method: "POST",
        body: register_data,

        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      // console.log("data:",data,register_data);
      go(data.message);
    } catch (error) {
      console.log("error:", error);
    }
  };

  let go = (msg) => {
    if (msg !== "Registration failed, user already exists") {
      navigate("/signin", { replace: true });
    } else {
      alert("Registration failed, user already exists");
    }
  };

  return (
    <div id="container">
      <div id="register-form">
        <h1>Create An Account</h1>

        <form>
          <p>
            Create Username
            <input
              required={true}
              onChange={(e) =>
                setregisterdata({
                  ...for_register_data,
                  username: e.target.value,
                })
              }
              type="text"
              id="username"
              placeholder=""
            />
          </p>
          <p>
            Email Address
            <input
              required={true}
              onChange={(e) =>
                setregisterdata({ ...for_register_data, email: e.target.value })
              }
              type="email"
              id="email"
              placeholder=""
            />
          </p>

          <p>
            Password
            <input
              required={true}
              onChange={(e) =>
                setregisterdata({
                  ...for_register_data,
                  password: e.target.value,
                })
              }
              type="password"
              id="password"
              placeholder=""
            />
          </p>
        </form>
        <button onClick={register}>Create An Account</button>
        <p>
          Already a member?
          <span>
            <Link className="link" style={{ marginLeft: "0px" }} to={"/signin"}>
              Click Here to Signup
            </Link>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Signup;
